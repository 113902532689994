@import '~antd/es/style/themes/default.less';

@basicLayout-prefix-cls: ~'@{ant-prefix}-pro-basicLayout';

.@{basicLayout-prefix-cls} {
  &:not('.ant-pro-basicLayout-mobile') {
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.06);
      border-radius: 3px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
    }

    /* 滚动条滑块 */
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.12);
      border-radius: 3px;
      -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }
  // BFC
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  .ant-layout-header {
    &:not(.ant-pro-top-menu) {
      background: @component-background;
    }

    &.ant-pro-fixed-header {
      position: fixed;
      top: 0;
    }
  }

  &-content {
    position: relative;
    margin: 24px;
    transition: all 0.2s;
    .@{ant-prefix}-pro-page-header-wrap {
      margin: -24px -24px 0;
    }
    &-disable-margin {
      margin: 0;
      > div > .@{ant-prefix}-pro-page-header-wrap {
        margin: 0;
      }
    }
    > .ant-layout {
      max-height: 100%;
    }
  }

  // children should support fixed
  .@{basicLayout-prefix-cls}-is-children.@{basicLayout-prefix-cls}-fix-siderbar {
    height: 100vh;
    overflow: hidden;
    transform: rotate(0);
  }

  .@{basicLayout-prefix-cls}-has-header {
    .@{basicLayout-prefix-cls}-is-children {
      min-height: calc(100vh - @layout-header-height);
      &.@{basicLayout-prefix-cls}-fix-siderbar {
        height: calc(100vh - @layout-header-height);
      }
    }
  }

  // tech-page-container
  .@{basicLayout-prefix-cls}-has-header .tech-page-container {
    height: calc(100vh - @layout-header-height);
  }

  .@{basicLayout-prefix-cls}-has-header
    .@{basicLayout-prefix-cls}-is-children.@{basicLayout-prefix-cls}-has-header {
    .tech-page-container {
      height: calc(100vh - @layout-header-height - @layout-header-height);
    }
  }
}

@primary-color: #B789BC;@background-color-light: hsv(0, 0, 99%);@menu-icon-size-lg: 32px;
@import '~antd/es/style/themes/default.less';

@pro-layout-fixed-header-prefix-cls: ~'@{ant-prefix}-pro-fixed-header';

.@{pro-layout-fixed-header-prefix-cls} {
  z-index: 9;
  width: 100%;
  transition: width 0.2s;
}

@primary-color: #B789BC;@background-color-light: hsv(0, 0, 99%);@menu-icon-size-lg: 32px;
@import '~antd/es/style/themes/default.less';
@pro-layout-grid-content-prefix-cls: ~'@{ant-prefix}-pro-grid-content';

.@{pro-layout-grid-content-prefix-cls} {
  width: 100%;
  min-height: 100%;
  transition: 0.3s;
  &.wide {
    max-width: 1200px;
    margin: 0 auto;
  }
}

@primary-color: #B789BC;@background-color-light: hsv(0, 0, 99%);@menu-icon-size-lg: 32px;
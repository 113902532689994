@import './index.less';
.@{ant-pro-setting-drawer}-content {
  .theme-color {
    margin-top: 24px;
    overflow: hidden;
    .theme-color-title {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 22px;
    }
    .theme-color-block {
      float: left;
      width: 20px;
      height: 20px;
      margin-right: 8px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      border-radius: 2px;
      cursor: pointer;
    }
  }
}

@primary-color: #B789BC;@background-color-light: hsv(0, 0, 99%);@menu-icon-size-lg: 32px;